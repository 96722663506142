import React from "react";
import { Link } from "gatsby";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import "../styles/page/news.css";
import prev from "../images/previous.svg";
import next from "../images/step-forward.svg";
const news = ({data,pageContext}) => {
  const cards = data.allStrapiArticle.edges.map(edge => edge.node);   
    const {currentPage, numPages} = pageContext
    const isFrist = currentPage === 1 ;
    const isLast = currentPage === numPages;
    const previousPagePath = currentPage  - 1 === 1 ? '/tin-tuc/1' : '/tin-tuc/' + (currentPage - 1).toString();
    const nextPagePath = '/tin-tuc/' + (currentPage + 1).toString();
    const prevPageClassName = isFrist ? "disabledButton" : "nextbutton";
    const nextPageClassName = isLast ? "disabledButton" : "nextbutton"
    const getPageNumberPath = (currentIndex) =>{
        if(currentIndex === 0){
            return '/tin-tuc/1'
        }
        return '/tin-tuc/' + (currentIndex + 1)
  }

  return (
   
      <Layout>
        <GatsbySeo title="Tin Tức"/>
                <div className="about">
                       <GatsbyImage image={data.strapiListArticlePage.image?.localFile?.childImageSharp.gatsbyImageData} alt={data.strapiListArticlePage.image.alternativeText} className="project__baner"/>
                        <div className="container">
                         <div className="baner__slider Home__siteTitle">
                             <div>
                                  <Link to="/" title="Trang-chu" className="baner__title">
                                      Trang Chủ
                                  </Link>
                                  <span className="baner__title">/ {data.strapiListArticlePage.title}</span>
                             </div>
                             <h2 className="baner__desc">{data.strapiListArticlePage.title}</h2>
                        </div>
                      </div>
                </div>
                 <div className="news__rows">
                    <div className="container">
                    </div>
                    <div className="container">
                    <div className="news">
                        {cards.map((item) => (
                       <div className="news__row" key={item.id}>
                            <Link
                                title={item.title}
                                to={`/tin-tuc/${item.slug}`}
                                 className="overflow-hidden rounded-lg bg-white shadow-sm transition-shadow hover:shadow-md"
                            >
                              <div className="hover01  column">
                                <figure><GatsbyImage imgStyle={{transition: " .0.3s ease-in-out 0s"}} className="img_news-hovers" image={item.image?.localFile?.childImageSharp.gatsbyImageData} alt={item.image.alternativeText} /></figure>
                              </div>
                             <div className="px-4 py-4 news-shadow">
                                 <div className="news__desc">
                                    <strong>{item.createdAt}</strong>
                                 </div>
                                <span className="news__cartogory">{item.category?.name}</span>
                                <h3 className="font-bold text-neutral-700">{item.title}</h3>
                                <p className="mt-2 text-neutral-500 line-clamp-2 news__desc-p">
                               {item.description}
                               </p>
                             </div>
                           </Link>
                         </div>
                        ))} 
                   </div>
                     <div className="next__news">
                            <Link className={prevPageClassName}  to={previousPagePath} rel="prev"> <img className="next__img" src={prev} /></Link>
                            {Array.from({ length: numPages }, (_, i) => {
                                 let pageNumberClassName = "pageNumber";
                               if (currentPage === i + 1) {
                                   pageNumberClassName = "currentPageNumber";
                                 }
                                 return (
                                    <Link className={pageNumberClassName}  key={i + 1} to={getPageNumberPath(i)}>
                                      {i + 1}
                                    </Link>
                                )
                            })}
                             <Link className={nextPageClassName}  to={nextPagePath} rel="prev"> <img className="next__img" src={next} /></Link>
                        </div>
                    </div>
                 </div>
        </Layout>
  );
};

export default news;
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!= 0 , $limit: Int! = 10  ) {
    allStrapiArticle(
      sort: { fields: [createdAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
        edges {
            node {
              category {
                name
              }    
              slug
              id
              description
              title
              createdAt
              image {
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80
                      webpOptions: {quality: 80}
                      placeholder: BLURRED)
                  }
                }
              }
            }
        }
    }
    strapiListArticlePage {
      image {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80
              webpOptions: {quality: 80}
              placeholder: BLURRED)
          }
        }
      }
      id
      title
    }
  }
`

